import type { Variant } from '@Types/product/Variant'
import { Price } from 'composable/components/price'
import { SwatchSelectorGrouped } from 'composable/components/types'
import keys from 'lodash/keys'
import { getPrice } from '../helpers'

type PdpPriceProps = {
  currentVariant?: Variant
  promotionVariant?: Variant
  swatchSelectorGroupedByPrice?: any[] | SwatchSelectorGrouped
  cartPromotion: any
  priceRange?: any
  priceByEdsCodes?: any
}
export const PdpPrice = ({
  promotionVariant,
  currentVariant,
  swatchSelectorGroupedByPrice,
  cartPromotion,
  priceRange,
  priceByEdsCodes,
}: PdpPriceProps) => {
  const price = getPrice({
    cartPromotion,
    promotionVariant,
    currentVariant,
    priceByEdsCodes,
  })
  const priceCustomFields = currentVariant?.priceCustomFields?.fields
  const basePrice = priceCustomFields?.basePrice?.centAmount
  const discountedPrice = promotionVariant
    ? promotionVariant?.discountedPrice?.centAmount
    : currentVariant?.discountedPrice?.centAmount
  const discountPercent =
    currentVariant?.discountPercent || priceCustomFields?.discountedPercent || basePrice > price
      ? Math.round(((basePrice - price) / basePrice) * 100)
      : undefined
  const rangePrice =
    priceRange?.minPrice && priceRange?.maxPrice
      ? {
          minPrice: priceRange?.minPrice,
          maxPrice: priceRange?.maxPrice,
        }
      : undefined

  return (
    <Price
      basePrice={basePrice}
      priceProps={{ textStyle: 'body-300' }}
      price={price}
      discountedPrice={discountedPrice}
      discountPercent={discountPercent ? Number(discountPercent) : undefined}
      promoPrice={cartPromotion ? cartPromotion?.discountPrice[0] : undefined}
      priceRange={rangePrice}
      priceGroupQuantity={swatchSelectorGroupedByPrice ? keys(swatchSelectorGroupedByPrice).length : 1}
      priceByEdsCodes={priceByEdsCodes}
    />
  )
}
