/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Image,
  Skeleton,
  SkeletonText,
  Stack,
  StackProps,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Product } from '@Types/product/Product'
import { Variant } from '@Types/product/Variant'
import lodashForEach from 'lodash/forEach'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import mapKeys from 'lodash/mapKeys'
import sum from 'lodash/sum'
import { Toaster } from 'react-hot-toast'
import { Category } from 'shared/types/product'
import { Gallery, ImagesAndVideos } from 'composable/components/gallery'
import {
  AFTERPAY_MESSAGING_MPID,
  AFTERPAY_MESSAGING_PLACEMENT_ID,
  DONATION_PRODUCT_ID,
  DONATION_PRODUCT_NAME,
  DONATION_SKU,
  GIFT_CARD_PRODUCT_ID,
  GIFT_CARD_PRODUCT_NAME,
  GIFT_CARD_SKU,
  LOW_STOCK_COUNT,
} from 'composable/components/general/constants'
import { getCartProductByVariantSkuFromAtgCart } from 'composable/helpers/utils/cart-utils'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import { constructUtagData, utagAddToCart, utagAddToWishlist, utagView } from 'helpers/tealiumHelper'
import { useAtgLegacyCart, useAtgUser, useStore } from 'frontastic/contexts'
import { myBag } from 'frontastic/contexts/atgCartContext/helpers/constants'
import { raiseToast } from 'frontastic/contexts/atgCartContext/helpers/utils'
import { DropShipProductMessage } from 'frontastic/tastics/composable/product-detail-page/components/DropShipProductMessage'
import { PdpMain } from 'frontastic/tastics/composable/product-detail-page/components/PdpMain'
import { PdpPrice } from 'frontastic/tastics/composable/product-detail-page/components/PdpPrice'
import { StockMessage } from 'frontastic/tastics/composable/product-detail-page/components/StockMessage'
import {
  allSkuAvailableQuantity,
  areAllAttributesSelectedFunction,
  findVariantBySelectedAttributes,
  getQuantityMax,
} from 'frontastic/tastics/composable/product-detail-page/components/utils'
import {
  useAvailableAttributesTextSelectors,
  useAvailableAttributesOptions,
} from 'frontastic/tastics/composable/product-detail-page/hooks'
import { HemmingChargesSection } from './components/hemming-charges-section'
import { Scene7ViewerScript } from './components/scene-7/scene-7-script'
import { SwatchContainer } from './components/swatch-container'
import { TextSelector } from './components/text-selector'
import { CustomerReviews } from './customer-reviews/customer-reviews'
import { useFindPriceByEdsCodes, useGetCartPromotion, useGetUniqueValidEdsCodes } from './hooks'
import SizeChartModal from './size-chart-modal'
import { SWATCH_VIEW_VALUES } from './types'
import { ToggleButtonGroup } from '../../../components/core-ui-controls/ToggleButton'
import { useHemmingChargesPerSegment } from '../../../helpers/hooks/useHemmingChargesPerSegment'
import { appendEpsilonScript } from '../../../helpers/utils/epsilonScript'
import { sanitizeProductName } from '../../helpers/utils/string-utils'
import AtgForgotPassword from '../account/atg-forgot-password'
import AtgLoginPage from '../account/atg-login-page'
import AfterPayMessaging from '../general/components/afterpaymessaging'
import ProductBadges from '../general/components/productbadges'
import { useCheckIfProductHasBadge } from '../hooks/useCheckIfProductHasBadge/utils'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { SwatchSelectorConfiguration, SwatchSelectorGrouped, TextSelectorOption } from '../types'

interface QuickViewProductDetailsLayoutProps {
  product: Product
  childProducts?: Product[]
  filteredChildProducts?: Product[]
  cartPromotions: any[]
  priceRange: any
  isProductInStock: boolean
  algoliaQueryId?: string
}

function QuickViewProductDetailsLayout(data: QuickViewProductDetailsLayoutProps) {
  const router = useRouter()
  const toast = useToast()

  const intl = useFormat({ name: 'common' })
  const { formattedCart: cart, addItem, addToWishlistLegacyCart } = useAtgLegacyCart()
  const {
    product,
    childProducts,
    filteredChildProducts,
    cartPromotions,
    priceRange,
    isProductInStock,
  }: {
    product: Product
    childProducts?: Product[]
    filteredChildProducts?: Product[]
    cartPromotions: any[]
    priceRange: any
    isProductInStock: boolean
  } = data

  const _childProducts = filteredChildProducts.length > 0 ? filteredChildProducts : childProducts
  const query = router?.query
  const upcCodeToMatch = Array.isArray(query.slug) ? query.slug[2] : ''

  const findUPCVariant = () => {
    if (!upcCodeToMatch) {
      return { variant: null, product: null }
    }
    for (const childProduct of _childProducts || []) {
      for (const variant of childProduct.variants || []) {
        const upcCodes = variant.attributes?.upcCodes
        if (Array.isArray(upcCodes) && upcCodes.includes(upcCodeToMatch) && variant.availableQuantity > 0) {
          return { variant, product: childProduct }
        }
      }
    }
    return { variant: null, product: null }
  }
  const { variant: matchingUPCVariant, product: matchingChildProduct } = findUPCVariant()

  const isGiftCardPdp: boolean = (router.query.gift as string) === 'true'
  const isDonationPdp: boolean = (router.query.donation as string) === 'true'
  const firstAvailableChildProduct = _childProducts?.find((childProduct: Product) =>
    childProduct?.variants?.some((variant: Variant) => variant?.availableQuantity > 0),
  )
  const initialChildProduct = firstAvailableChildProduct ?? childProducts?.[0]
  const initialSwatch = initialChildProduct?.variants?.[0]?.attributes?.ecommColor
  const [currentChildProduct, setCurrentChildProduct] = useState<Product>(initialChildProduct)
  const [currentVariant, setCurrentVariant] = useState<Variant>(initialChildProduct?.variants?.[0])
  const [quantity, setQuantity] = useState(1)
  const [giftCardAmount, setGiftCardAmount] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedAttributes, setSelectedAttributes] = useState<{ [key: string]: string }>({})
  const [showMissingSelections, setShowMissingSelections] = useState<boolean>(false)
  const { userSessionData, userDetails } = useAtgUser()
  const [isWishListLoading, setWishListIsLoading] = useState(false)
  const [productAddedToWishList, setProductAddedToWishList] = useState(false)
  const [isLoginModelOpen, setLoginModelOpen] = useState(false)
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
  const [algoliaProductAddedToCart, setAlgoliaProductAddedToCart] = useLocalStorage('algoliaProductAddedToCart', [])
  const [selectedSwatch, setSelectedSwatch] = useState<string>(initialSwatch)
  const isLoggedIn = !!userSessionData?.firstName
  const { cartData } = useAtgLegacyCart()

  const variantCountInCart = useMemo(
    () => getCartProductByVariantSkuFromAtgCart({ variantSku: currentVariant?.sku, cart: cart }),
    [currentVariant?.sku, cart?.lineItems],
  )

  const triggerVariantChange = (selectedAttributes: { [key: string]: string }, currentChildProduct?: Product) => {
    setShowMissingSelections(false)
    const selectedVariant = findVariantBySelectedAttributes(selectedAttributes, currentChildProduct)

    const selectedVariantAvailableQuantity = selectedVariant?.availableQuantity
    const maxAvailableQty =
      selectedVariant?.availability?.backorderlevel > 0
        ? selectedVariant.availability.backorderlevel
        : selectedVariantAvailableQuantity - variantCountInCart

    if (maxAvailableQty < quantity) {
      setQuantity(maxAvailableQty < 0 ? 0 : maxAvailableQty)
      if (maxAvailableQty > 0) {
        raiseToast(
          myBag,
          intl.formatMessage({
            id: 'checkout.product.maxAvailableQuantity',
            values: { maxAvailableQty },
          }),
          'info',
          toast,
        )
      }
    } else if (quantity <= 0) {
      setQuantity(1) // reinitializing the quantity counter
    }

    const variantChangeCondition = selectedVariant && selectedVariant?.id !== currentVariant?.id
    if (variantChangeCondition) setCurrentVariant(selectedVariant)
  }

  const availableAttributesTextSelectorsLabels = {
    segment2: !!product.attributes?.segment2Label ? String(product.attributes?.segment2Label) : '',
    segment3: !!product.attributes?.segment3Label ? String(product.attributes?.segment3Label) : '',
  }

  const textSelectorAttributes = Object.keys(availableAttributesTextSelectorsLabels)

  const availableAttributesOptions = useAvailableAttributesOptions({ childProducts, textSelectorAttributes })

  const availableAttributesTextSelectors = useAvailableAttributesTextSelectors({
    availableAttributesOptions,
    cart,
    variants: currentChildProduct?.variants,
    selectedAttributes,
  })

  const textSelectorsMissingSelection = useMemo<string[]>(
    () =>
      Object.values(availableAttributesTextSelectors)
        .filter(
          (textSelector: { attribute?: { value?: string | undefined | null } }) => !textSelector?.attribute?.value,
        )
        .map((textSelector: { attribute?: { label?: string | undefined | null } }) => textSelector?.attribute?.label),
    [availableAttributesTextSelectors],
  )

  const stockIsSet = currentVariant?.availableQuantity !== undefined
  const areAllAttributesSelected = areAllAttributesSelectedFunction(
    availableAttributesTextSelectorsLabels,
    selectedAttributes,
  )

  const isInStock =
    stockIsSet &&
    currentVariant?.availableQuantity > 0 &&
    currentVariant?.availableQuantity - variantCountInCart >= quantity
  const isLowStock =
    stockIsSet &&
    currentVariant?.availableQuantity - variantCountInCart <= LOW_STOCK_COUNT &&
    currentVariant?.availableQuantity - variantCountInCart > 0 &&
    currentVariant?.availableQuantity - variantCountInCart >= quantity

  const handleAddToCart = async (
    variant: Variant,
    quantity: number,
    giftCardAmount?: number,
    isGiftCard: boolean = false,
    isDonation: boolean = false,
  ) => {
    if (textSelectorsMissingSelection.length > 0) {
      return setShowMissingSelections(true)
    }

    const algoliaQueryId = data?.algoliaQueryId || ''
    setIsLoading(true)
    if (isGiftCard) {
      await addItem(GIFT_CARD_PRODUCT_ID, GIFT_CARD_SKU, quantity, GIFT_CARD_PRODUCT_NAME, '', '', giftCardAmount)
    } else if (isDonation) {
      await addItem(DONATION_PRODUCT_ID, DONATION_SKU, quantity, DONATION_PRODUCT_NAME, '', '', giftCardAmount)
    } else {
      await addItem(variant?.attributes?.itemNumber, variant?.sku, quantity, product?.name)
    }

    utagAddToCart({
      product,
      currentVariant,
      variant,
      qty: quantity,
      userSessionData,
      userDetails,
      cartData,
      algoliaQueryId,
    })
    setIsLoading(false)
    const productAdded = algoliaProductAddedToCart
    productAdded.push({
      item_id: variant?.attributes?.itemNumber,
      algolia_query_id: algoliaQueryId ? `${variant?.attributes?.itemNumber} ${algoliaQueryId}` : '',
    })
    setAlgoliaProductAddedToCart(productAdded)
  }

  const handleAddToWishList = async () => {
    if (textSelectorsMissingSelection.length > 0) {
      setShowMissingSelections(true)
    } else {
      setShowMissingSelections(false)
      if (isLoggedIn) {
        addToWishlistFn()
      } else {
        setLoginModelOpen(true)
      }
    }
  }

  const toggleForgotPassword = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal)
    if (isLoginModelOpen) {
      setLoginModelOpen(false)
    }
  }

  const closeLoginModal = (obj) => {
    if (obj?.close == 'byClick') {
      setLoginModelOpen(false)
    }
  }

  useEffect(() => {
    callBackAfterLogin()
  }, [userSessionData?.firstName])

  const callBackAfterLogin = () => {
    if (isLoginModelOpen) {
      setLoginModelOpen(false)
      addToWishlistFn()
    }
  }

  const addToWishlistFn = async () => {
    setWishListIsLoading(true)
    await addToWishlistLegacyCart(
      currentVariant?.attributes?.itemNumber,
      currentVariant?.sku,
      quantity,
      product.name,
    ).then(() => {
      setWishListIsLoading(false)
      setProductAddedToWishList(true)
      utagAddToWishlist({
        product,
        currentVariant,
        qty: quantity,
        userSessionData,
        cartData,
        algoliaQueryId: data?.algoliaQueryId || '',
      })
    })
  }

  const allUniqueValidEdsCodes = useGetUniqueValidEdsCodes(childProducts)
  const cartPromotion = useGetCartPromotion(cartPromotions, allUniqueValidEdsCodes)
  const priceByEdsCodes = useFindPriceByEdsCodes(childProducts, allUniqueValidEdsCodes)

  const promotionVariant = useMemo(() => {
    const allVariants = _childProducts?.flatMap((product) => product?.variants)
    return allVariants?.find((variant) => variant?.promotion === cartPromotion?.key)
  }, [cartPromotion, _childProducts])

  const availableSwatches = useMemo(() => {
    const initialAccumulator = {
      attribute: {
        label: 'Color',
        value: currentChildProduct?.variants?.[0]?.attributes?.ecommColor,
        colorDisplayName: currentChildProduct?.variants?.[0]?.attributes?.colorDisplayName ?? undefined,
      },

      options: [],
    }

    if (filteredChildProducts.length === 0) {
      return undefined
    }

    return filteredChildProducts?.reduce((acc: SwatchSelectorConfiguration, childProduct: Product) => {
      const swatchValue = childProduct?.variants?.[0]?.attributes?.ecommColor
      const swatch = childProduct?.swatch
      const swatchUrl = childProduct?.variants?.[0]?.attributes?.swatchUrl

      const isSelectedAttributesMatchingVariantAvailable = findVariantBySelectedAttributes(
        selectedAttributes,
        childProduct,
      )

      const basePrice = childProduct?.variants?.[0]?.priceCustomFields?.fields?.basePrice
      const discountedPrice = childProduct?.variants?.[0]?.discountedPrice
      const price = childProduct?.variants?.[0]?.price

      const finalSellingPrice = discountedPrice ? discountedPrice : price
      const finalOriginalPrice = discountedPrice
        ? price
        : basePrice?.centAmount > price?.centAmount
          ? basePrice
          : undefined

      const discountAsPerValues = Math.floor(
        ((basePrice.centAmount - finalSellingPrice.centAmount) / basePrice.centAmount) * 100,
      )

      const swatchOption = {
        value: swatchValue,
        colorDisplayName: childProduct?.variants?.[0]?.attributes?.colorDisplayName ?? undefined,
        inStock: {
          ...isSelectedAttributesMatchingVariantAvailable,
          allSkuAvailableQuantity: allSkuAvailableQuantity(childProduct?.variants),
        },
        swatchUrl,
        swatch,
        priceGroup: {
          price: finalSellingPrice,
          basePrice: finalOriginalPrice,
          discountPercent: discountAsPerValues || 0,
        },
      }

      const newOptions = [...acc.options, swatchOption]
      return { ...acc, options: newOptions }
    }, initialAccumulator)
  }, [_childProducts, selectedAttributes, currentChildProduct?.variants])

  const swatchSelectorGroupedByPrice = useMemo(() => {
    if (!availableSwatches) return []

    const swatchSelectorGroupedByDiscounts = groupBy(availableSwatches?.options, 'priceGroup.discountPercent')
    const mappedSwatchSelectorGrouped = mapKeys(swatchSelectorGroupedByDiscounts, (value, key) => {
      return key === 'undefined' ? '0' : key
    }) as SwatchSelectorGrouped

    return lodashForEach(mappedSwatchSelectorGrouped, (swatchSelector) =>
      swatchSelector.sort((swatch: any) => (swatch?.inStock ? -1 : 1)),
    )
  }, [availableSwatches])

  const setSelectedAttribute = (attribute: string, value: string) => {
    setSelectedAttributes((prev) => {
      const isAttributeAlreadySelected = prev[attribute] === value

      if (isAttributeAlreadySelected) {
        const newSelectedAttributes = { ...prev }
        delete newSelectedAttributes[attribute]
        return newSelectedAttributes
      } else {
        return { ...prev, [attribute]: value }
      }
    })
  }

  const triggerSwatchChange = (selectedSwatch: string) => {
    setShowMissingSelections(false)
    const selectedChildProduct = _childProducts?.find((childProduct: Product) => {
      return childProduct.variants?.every((variant: Variant) => {
        return variant.attributes?.ecommColor === selectedSwatch
      })
    })

    const swatchChangeCondition =
      selectedChildProduct && selectedChildProduct?.productId !== currentChildProduct?.productId

    if (swatchChangeCondition) {
      setCurrentChildProduct(selectedChildProduct)
      setSelectedAttributes({}) // Clear all selected attributes since the new Variant may not have available inventory
    }

    triggerVariantChange(selectedAttributes, selectedChildProduct)
  }

  useEffect(() => {
    if (matchingUPCVariant) {
      setCurrentVariant(matchingUPCVariant)
      setCurrentChildProduct(matchingChildProduct)
      setSelectedSwatch(matchingUPCVariant.attributes?.ecommColor)
    } else {
      setCurrentVariant(initialChildProduct?.variants?.[0])
      setSelectedSwatch(initialSwatch)
    }
  }, [product.slug, matchingUPCVariant, matchingChildProduct])

  useEffect(() => {
    triggerVariantChange(selectedAttributes, currentChildProduct)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAttributes])

  useEffect(() => {
    triggerSwatchChange(selectedSwatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSwatch])

  useEffect(() => {
    setQuantity(1)
  }, [product?.key, _childProducts])

  const isBackordered = currentVariant.availability?.backorderlevel > 0

  const quantityMax = getQuantityMax({
    currentVariant,
    isBackordered,
    stockIsSet,
    variantCountInCart,
  })

  if (!product || !currentChildProduct || !currentVariant) return null

  return (
    <>
      <QuickViewProductContent
        title={product?.name}
        productId={product.productId}
        description={product.description}
        isLoaded={true}
        isHemmingChargeable={!!product?.attributes?.isHemmingChargeable}
        currentVariant={currentVariant}
        currentChildProduct={currentChildProduct}
        product={product}
        priceRange={data?.priceRange || ''}
        algoliaQueryId={data?.algoliaQueryId || ''}
        isInStock={isInStock}
        isLowStock={isLowStock}
        isBackordered={isBackordered}
        availabilityDate={currentVariant.availability?.availabilitydate}
        firstAvailableChildProduct={firstAvailableChildProduct}
        isDonationPdp={isDonationPdp}
        isGiftCardPdp={isGiftCardPdp}
        areAllAttributesSelected={areAllAttributesSelected}
        main={
          <PdpMain
            quantity={quantity}
            quantityMin={1}
            quantityMax={quantityMax}
            areAllAttributesSelected={areAllAttributesSelected}
            isDonationPdp={isDonationPdp}
            isGiftCardPdp={isGiftCardPdp}
            giftCardAmount={giftCardAmount}
            isWishListLoading={isWishListLoading}
            isInStock={isInStock}
            isLowStock={isLowStock}
            isBackordered={isBackordered}
            isLoading={isLoading}
            productAddedToWishList={productAddedToWishList}
            currentVariant={currentVariant}
            handleChangeQuantityPicker={setQuantity}
            handleAddToCart={handleAddToCart}
            handleAddToWishList={handleAddToWishList}
            firstAvailableChildProduct={firstAvailableChildProduct}
          />
        }
        price={
          <PdpPrice
            promotionVariant={promotionVariant}
            currentVariant={currentVariant}
            swatchSelectorGroupedByPrice={swatchSelectorGroupedByPrice}
            cartPromotion={cartPromotion}
            priceRange={priceRange}
            priceByEdsCodes={priceByEdsCodes}
          />
        }
        itemPriceValue={
          promotionVariant
            ? parseFloat(
                CurrencyHelpers.formatForCurrency(promotionVariant?.price?.centAmount).replace(/[^0-9.-]+/g, ''),
              )
            : parseFloat(CurrencyHelpers.formatForCurrency(currentVariant?.price?.centAmount).replace(/[^0-9.-]+/g, ''))
        }
        textSelectors={availableSwatches ? availableAttributesTextSelectors : undefined}
        textSelectorsLabels={availableSwatches ? availableAttributesTextSelectorsLabels : undefined}
        childProducts={childProducts}
        showMissingSelections={showMissingSelections}
        selectedAttributes={selectedAttributes}
        mainStackProps={{
          position: 'sticky',
          height: 'fit-content',
          top: '12',
        }}
        stackProps={{
          direction: { base: 'column-reverse', lg: 'row-reverse' },
        }}
        isProductInStock={isProductInStock}
        swatchSelector={availableSwatches}
        setSelectedAttribute={setSelectedAttribute}
        setSelectedSwatch={setSelectedSwatch} // added
        swatchSelectorGroupedByPrice={swatchSelectorGroupedByPrice}
      />
      <AtgLoginPage isOpen={isLoginModelOpen} onClose={closeLoginModal} toggleForgotPassword={toggleForgotPassword} />
      <AtgForgotPassword isOpen={showForgotPasswordModal} onClose={toggleForgotPassword} />
    </>
  )
}

type TextSelectorProps = {
  attribute: {
    label: string
    value: string
  }
  options: TextSelectorOption[]
}

interface QuickViewProductContentProps {
  productId: string
  description: string
  isLoaded: boolean
  isHemmingChargeable: boolean
  main: JSX.Element
  price: JSX.Element
  itemPriceValue: number
  title: string
  stackProps?: StackProps
  mainStackProps?: StackProps
  childProducts?: Product[]
  currentChildProduct?: Product
  currentVariant?: Variant
  textSelectors?: {
    [key: string]: TextSelectorProps
  }
  textSelectorsLabels?: {
    [key: string]: string
  }
  setSelectedAttribute?: (attribute: string, value: string) => void
  selectedAttributes?: {
    [key: string]: string
  }
  showMissingSelections?: boolean
  isProductInStock?: boolean
  product?: Product
  swatchSelector?: SwatchSelectorConfiguration
  swatchSelectorGroupedByPrice?: SwatchSelectorGrouped | any[]
  category?: Category
  setSelectedSwatch?: (value: string) => void // added
  priceRange?: string
  algoliaQueryId?: string
  isInStock?: boolean
  isLowStock?: boolean
  isBackordered?: boolean
  availabilityDate?: string
  firstAvailableChildProduct?: any
  isDonationPdp?: boolean
  isGiftCardPdp?: boolean
  areAllAttributesSelected?: boolean
}

const QuickViewProductContent = ({
  title,
  description,
  price,
  itemPriceValue,
  main,
  productId,
  isLoaded,
  isHemmingChargeable,
  mainStackProps,
  currentVariant,
  textSelectors,
  textSelectorsLabels,
  selectedAttributes,
  showMissingSelections,
  isProductInStock,
  category,
  product,
  currentChildProduct,
  swatchSelector,
  setSelectedAttribute,
  swatchSelectorGroupedByPrice,
  setSelectedSwatch,
  priceRange,
  algoliaQueryId,
  isInStock,
  isLowStock,
  isBackordered,
  availabilityDate,
  firstAvailableChildProduct,
  isDonationPdp,
  isGiftCardPdp,
  areAllAttributesSelected,
}: QuickViewProductContentProps) => {
  const [swatchViewType, setSwatchViewType] = useState<string>(SWATCH_VIEW_VALUES.GRID)
  const [selectedHemmingCharge, setSelectedHemmingCharge] = useState<any>()
  const [isSizeChartModalOpen, setIsSizeChartModalOpen] = useState(false)
  const [isDenimModalOpen, setIsDenimModalOpen] = useState(false)
  const [isRiseModalOpen, setIsRiseModalOpen] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const intl = useFormat({ name: 'common' })
  const { userDetails, userSessionData } = useAtgUser()
  const [isUtagViewCalled, setIsUtagViewCalled] = useState(false)
  const router = useRouter()
  const path = router.asPath
  const { currentPDPVariant, isStoreDataLoading } = useStore()
  const { formatMessage } = useFormat({ name: 'common' })
  const isDropShipProduct = currentVariant?.attributes?.isDropShip
  const isShowPromoExclusion = product?.attributes?.showPromoExclusion

  const promoExclusionMessage = intl.formatMessage({ id: 'pdp.promotionexclution.message' })

  const handleViewFullDetails = () => {
    currentVariant?.attributes?.ecommColor
      ? router.push(`/p/${product.slug}?swatch=${currentVariant?.attributes?.ecommColor}`)
      : router.push(`/p/${product.slug}`)
  }

  const handleOpenSizeChartModal = () => {
    setIsSizeChartModalOpen(true)
  }

  const handleCloseSizeChartModal = () => {
    setIsSizeChartModalOpen(false)
  }

  const handleOpenDenimModal = () => {
    setIsDenimModalOpen(true)
  }

  const handleCloseDenimModal = () => {
    setIsDenimModalOpen(false)
  }

  const handleOpenRiseModal = () => {
    setIsRiseModalOpen(true)
  }

  const handleCloseRiseModal = () => {
    setIsRiseModalOpen(false)
  }

  const updateCurrentImageIndex = (index: number) => {
    setCurrentImageIndex(index)
  }

  const productVideos: ImagesAndVideos[] = extractVideosFromParentProduct(product)

  const currentImages = useMemo(
    () =>
      currentVariant?.images?.map((img: string, id: number) => ({
        id: `${currentVariant?.sku}-${id}`,
        src: img,
        alt: currentVariant?.sku,
        type: 'image',
      })),
    [currentVariant],
  )

  const getBrandName = (product: any) => {
    let bName = ''
    if (product?.brand) {
      bName = product?.brand
    }
    if (product?.attributes?.brand) {
      bName = product?.attributes?.brand
    }
    return bName
  }

  const getCatName = (category: any, index) => {
    index = index - 1
    if (category?.breadcrumbs[index]) {
      const label = category?.breadcrumbs[index].label
      return label ? label : null
    }
    return null
  }

  const getAllAvailableSizes = () => {
    const values = textSelectors
      ? Object.values(textSelectors).flatMap((textSelector) => textSelector?.options?.map((option) => option.value))
      : []
    return values
  }

  const tealiumUtagView = (category) => {
    const utag_data = constructUtagData(path, userDetails, userSessionData, {
      tealium_event: 'product_view',
      product_algolia_query_id: [algoliaQueryId ?? ''],
      cm_page_id: 'Product: ' + title + ' (' + product?.key + ')',
      cm_category_id: category?.breadcrumbs?.length
        ? `/c/${category?.breadcrumbs[category?.breadcrumbs?.length - 1]?.link}`
        : null,
      page_type: 'product',
      page_name: category?.breadcrumbs?.map((breadcrumb) => breadcrumb.label).join(' > ') + ` > ${product?.slug}`,
      page_category: 'product',
      representative_sku_id: [currentVariant?.sku],
      product_variant: [],
      page_url: path,
      product_id: [product.key],
      attentiveId: `${product.key}`,
      product_name: [title],
      product_brand: [product.attributes?.brand ?? null],
      product_color: [],
      product_fitsize: '',
      product_available_sizes: getAllAvailableSizes(),
      product_list_price: [
        currentVariant?.discountedPrice
          ? (currentVariant?.discountedPrice?.centAmount / 100).toFixed(2)
          : (currentVariant?.price?.centAmount / 100).toFixed(2),
      ],
      product_unit_price: [(currentVariant?.price?.centAmount / 100).toFixed(2)],
      product_discount_amount: [
        currentVariant?.discountedPrice
          ? ((currentVariant?.discountedPrice?.centAmount - currentVariant?.price?.centAmount) / 100).toFixed(2)
          : 0,
      ],
      site_section: [category?.breadcrumbs[0]?.label ?? undefined],
      site_sub_section: [category?.breadcrumbs[1]?.label ?? undefined],
      site_sub_section_level3: [
        category?.breadcrumbs[2] && category?.breadcrumbs[2]?.link ? category?.breadcrumbs[2]?.label : undefined,
      ],
      product_category: [category?.breadcrumbs[0]?.label ?? undefined],
      product_category2: [category?.breadcrumbs[1]?.label ?? undefined],
      product_category3: [
        category?.breadcrumbs[2] && category?.breadcrumbs[2]?.link ? category?.breadcrumbs[2]?.label : undefined,
      ],
      event_type: 'product details',
      site_breadcrumb: category?.breadcrumbs?.map((breadcrumb) => breadcrumb.label).join(' > '),
      site_id: 'dxl',
      customer_email: userDetails?.profile?.['email'] || undefined,
      customer_postal_code: userDetails?.addressList?.[0]?.postalCode || undefined,
      customer_id: userDetails?.profile?.['customerId'] || undefined,
      crm_customer_number: userDetails?.profile?.['crmId'] ? userDetails?.profile?.['crmId'] : undefined,
    })

    if (path.includes('/p/')) {
      utagView(utag_data, userSessionData?.dxlCountry, userSessionData?.dxlCurrency)
    }
    setIsUtagViewCalled(true)
  }
  const isBackOrderLevel = (currentVariant: Variant) => {
    // Todo to add backorderlevel check currently this value is not available
    return currentVariant?.isOnStock && currentVariant?.availableQuantity > 0
  }
  const isAfterPayEligible = useMemo(() => {
    return !!!currentChildProduct?.attributes?.isDropShip && isBackOrderLevel(currentVariant)
  }, [currentChildProduct, currentVariant])

  const swatchSelectorGroupedByPriceSize = sum(
    map(swatchSelectorGroupedByPrice as any, (swatch: any) => {
      return swatch.length
    }),
  )

  const showDenimModal =
    typeof product.attributes.primaryCategory === 'string' &&
    product.attributes.primaryCategory
      .toLowerCase()
      .split('=>')
      .some((category) => category.includes('jeans') || category.includes('pants'))

  const order = ['Size', 'Waist', 'Rise', 'Inseam', 'Cuff', 'Shoe Size', 'Shoe Width']

  const sortedTextSelectors = useMemo(() => {
    return (
      textSelectors &&
      Object.values(textSelectors).sort((a, b) => {
        const labelA = textSelectorsLabels[a.attribute.label]
        const labelB = textSelectorsLabels[b.attribute.label]
        let indexA = order.indexOf(labelA) === -1 ? order.length : order.indexOf(labelA)
        let indexB = order.indexOf(labelB) === -1 ? order.length : order.indexOf(labelB)
        return indexA - indexB
      })
    )
  }, [textSelectors, textSelectorsLabels])

  const { hasBadge, badgeValues } = useCheckIfProductHasBadge(product, false, currentPDPVariant, router.asPath)

  const productBrandName = getBrandName(product)
  const defaultSizeChartModalSlugName = 'size_chart_modal'
  const defaultDenimModalSlugName = 'denim_modal'
  const defaultRiseModalSlugName = 'rise_modal'
  const brandSpecificSizeChartModalSlugName = sanitizeProductName(productBrandName, defaultSizeChartModalSlugName)
  const brandSpecificDenimSlugName = sanitizeProductName(productBrandName, defaultDenimModalSlugName)
  const brandSpecificRiseSlugName = sanitizeProductName(productBrandName, defaultRiseModalSlugName)
  const sizeChartSlugNameArray = [defaultSizeChartModalSlugName, brandSpecificSizeChartModalSlugName]
  const denimSlugNameArray = [defaultDenimModalSlugName, brandSpecificDenimSlugName]
  const riseSlugNameArray = [defaultRiseModalSlugName, brandSpecificRiseSlugName]

  const { hemmingChargesPerSegment } = useHemmingChargesPerSegment({
    isHemmingChargeable,
    selectedAttributes,
  })

  useEffect(() => {
    if (userDetails && userSessionData) {
      if (!isUtagViewCalled) {
        tealiumUtagView(category)
      }
    }
  }, [userDetails, userSessionData])

  useEffect(() => {
    const epsilonConfig = {
      dtm_fid: 5419,
      dtm_promo_id: 5,
      dtmc_department: getCatName(category, 1),
      dtmc_category: getCatName(category, 2),
      dtmc_sub_category: getCatName(category, 3),
      dtmc_product_id: productId,
      dtmc_brand: getBrandName(product),
      dtm_user_id: userDetails?.profile?.['crmId'] ? userDetails?.profile?.['crmId'] : null,
      dtm_email_hash: userDetails?.profile?.['email'] ? userDetails?.profile?.['email'] : null,
    }
    appendEpsilonScript(epsilonConfig, 'epsilonProduct')
  }, [category])

  return (
    <>
      <Box>
        <Toaster />
        <Container maxW="container.2xl" mx="auto" px={2}>
          <Stack flexDirection={{ base: 'column', md: 'row' }} gap={1}>
            <ImageSection
              productId={product.productId}
              images={currentImages}
              videos={productVideos}
              onClick={onOpen}
              updateCurrentImageIndex={updateCurrentImageIndex}
            />
            <Stack direction={{ base: 'column-reverse', lg: 'row' }} spacing={{ base: '10', lg: '16', xl: '24' }}>
              <Stack
                maxH={{ base: 'initial', lg: '95vh' }}
                maxW={{ lg: 'md' }}
                overflow={'hidden'}
                justify="flex-start"
                width="100%"
                layerStyle={'no-scroll-bar'}
                {...mainStackProps}
              >
                <Stack m={0} overflowY={'scroll'} gap={{ base: 5, md: 6 }}>
                  <Stack gap={2}>
                    <Box display={'flex'} gap={2}>
                      <Box width={'100%'}>
                        <Flex justifyContent="space-between" gap={2} pb={4}>
                          <Box display="flex">{hasBadge && <ProductBadges badgeValues={badgeValues} />}</Box>
                        </Flex>
                        <Flex justifyContent="space-between" alignItems="center">
                          <SkeletonText textStyle={'eyebrow-75'} color={'text.muted'} isLoaded={isLoaded}>
                            {product?.attributes?.brand}
                          </SkeletonText>
                        </Flex>
                        <Heading>
                          <Skeleton textStyle="heading-desktop-200" isLoaded={isLoaded} mt={2}>
                            {title}
                          </Skeleton>
                        </Heading>
                      </Box>
                    </Box>

                    {product && (
                      <Skeleton isLoaded={isLoaded}>
                        <Box id="prQuickView" paddingLeft={0} marginLeft={0} pointerEvents="none" cursor="default">
                          <CustomerReviews product={product} />
                        </Box>
                      </Skeleton>
                    )}
                    <SkeletonText isLoaded={isLoaded} textStyle={'body-100-300'}>
                      {price}
                    </SkeletonText>
                    {userSessionData?.dxlCountry == 'US' && (
                      <Skeleton isLoaded={isLoaded}>
                        <Box paddingLeft={0} marginLeft={0}>
                          <AfterPayMessaging
                            placementId={AFTERPAY_MESSAGING_PLACEMENT_ID}
                            amount={itemPriceValue}
                            currency={userSessionData?.dxlCurrency}
                            consumerLocale={'en_US'}
                            itemSkus={product?.key}
                            isEligible={isAfterPayEligible}
                            mpid={AFTERPAY_MESSAGING_MPID}
                            priceRange={priceRange}
                          />
                        </Box>
                      </Skeleton>
                    )}
                  </Stack>

                  {isProductInStock && (
                    <>
                      <Skeleton isLoaded={isLoaded}>
                        <Flex h={5} mb={3} alignItems={'center'}>
                          <Flex alignItems="flex-start" flex={1}>
                            <Text textStyle={'heading-desktop-75'} pe={2}>
                              {swatchSelector?.attribute?.label}
                            </Text>
                            <Text textStyle={'body-75'} textTransform={'capitalize'} lineHeight="120%">
                              {swatchSelector?.attribute?.colorDisplayName
                                ? swatchSelector?.attribute?.colorDisplayName.toLowerCase()
                                : swatchSelector?.attribute?.value.toLowerCase()}
                            </Text>
                          </Flex>
                          {swatchSelectorGroupedByPriceSize > 1 && (
                            <Flex alignItems="flex-end">
                              <ToggleButtonGroup
                                onChange={(v) => setSwatchViewType(v[0] as string)}
                                allowNone={false}
                                variant={'outline'}
                                exclusive
                                initialIndex={0}
                              >
                                <Button value={SWATCH_VIEW_VALUES.GRID}>Grid</Button>
                                <Button value={SWATCH_VIEW_VALUES.LIST}>List</Button>
                              </ToggleButtonGroup>
                            </Flex>
                          )}
                        </Flex>
                        {swatchSelectorGroupedByPrice && (
                          <SwatchContainer
                            swatchSelectorGroupedByPrice={swatchSelectorGroupedByPrice}
                            swatchViewType={swatchViewType}
                            swatchSelector={swatchSelector}
                            isStoreDataLoading={isStoreDataLoading}
                            setSelectedSwatch={setSelectedSwatch}
                          />
                        )}
                      </Skeleton>
                      {sortedTextSelectors?.map((textSelector, i) => {
                        const { attribute, options } = textSelector
                        return (
                          <Skeleton key={`text-selector-${i}`} isLoaded={isLoaded}>
                            <Flex h={5} mb={3} alignItems={'center'}>
                              {textSelectorsLabels[attribute.label] === 'Rise' ? (
                                <Box
                                  display={'flex'}
                                  gap={'4px'}
                                  justifyContent={'left'}
                                  alignItems={'center'}
                                  onClick={handleOpenRiseModal}
                                  cursor="pointer"
                                >
                                  <Box fontSize={'14px'} textStyle="body-75" fontWeight={600}>
                                    {intl.formatMessage({
                                      id: 'text.sizeChart.rise',
                                      defaultMessage: 'Rise',
                                      values: { brandName: '' },
                                    })}
                                  </Box>
                                  <Icon as={InfoOutlineIcon} w={4} h={4} />
                                </Box>
                              ) : (
                                <Text textStyle={'heading-desktop-75'} pe={2} textTransform={'capitalize'}>
                                  {textSelectorsLabels[attribute.label]}
                                </Text>
                              )}
                              {attribute?.value ? (
                                <Text textStyle={'body-75'} ml={1}>
                                  {attribute.value}
                                </Text>
                              ) : (
                                showMissingSelections && (
                                  <Text textStyle={'body-75'} color={'text.danger'} ml={1}>
                                    {intl.formatMessage({
                                      id: 'product.pleaseSelect',
                                      values: { attribute: textSelectorsLabels[attribute.label] },
                                    })}
                                  </Text>
                                )
                              )}
                            </Flex>

                            <Flex gap={2.5} flexWrap={'wrap'}>
                              {options.map((option, index) => {
                                return (
                                  <Box key={`swatch-${index}`}>
                                    <TextSelector
                                      option={option}
                                      isSelected={option.value === attribute.value}
                                      isDisabled={!option?.inStock}
                                      setSelection={() => {
                                        setSelectedAttribute(attribute.label, option.value)
                                      }}
                                      isStoreDataLoading={false}
                                    />
                                  </Box>
                                )
                              })}
                            </Flex>
                          </Skeleton>
                        )
                      })}

                      {hemmingChargesPerSegment && (
                        <HemmingChargesSection
                          hemmingChargesPerSegment={hemmingChargesPerSegment}
                          selectedHemmingCharge={selectedHemmingCharge}
                          setSelectedHemmingCharge={setSelectedHemmingCharge}
                          isLoaded={isLoaded}
                          intl={intl}
                          isStoreDataLoading={false}
                        />
                      )}
                    </>
                  )}

                  <Box display={'flex'} gap={'16px'} paddingTop={0} paddingBottom={3}>
                    <Box
                      display={'flex'}
                      gap={'4px'}
                      justifyContent={'left'}
                      alignItems={'center'}
                      onClick={handleOpenSizeChartModal}
                      cursor="pointer"
                    >
                      <Image src="/ruler.svg" w={4} h={4} alt="Ruler Icon" />
                      <Box fontSize={'14px'} textStyle="body-75" fontWeight={600} textDecoration="underline">
                        {intl.formatMessage({
                          id: 'text.sizeChart.title',
                          defaultMessage: 'Size Chart',
                          values: { brandName: '' },
                        })}
                      </Box>
                    </Box>
                    {isSizeChartModalOpen && (
                      <SizeChartModal
                        isOpen={isSizeChartModalOpen}
                        onClose={handleCloseSizeChartModal}
                        slug={sizeChartSlugNameArray}
                        messageID="text.sizeChart.title"
                        brandName={productBrandName}
                      />
                    )}
                    {showDenimModal && (
                      <Box
                        display={'flex'}
                        gap={'4px'}
                        justifyContent={'left'}
                        alignItems={'center'}
                        onClick={handleOpenDenimModal}
                        cursor="pointer"
                      >
                        <Image src="/pants.svg" w={4} h={4} alt="Pants Icon" />
                        <Box fontSize={'14px'} textStyle="body-75" fontWeight={600} textDecoration="underline">
                          {intl.formatMessage({
                            id: 'text.sizeChart.denim',
                            defaultMessage: 'Denim Guide',
                            values: { brandName: '' },
                          })}
                        </Box>
                      </Box>
                    )}
                    {isDenimModalOpen && (
                      <SizeChartModal
                        isOpen={isDenimModalOpen}
                        onClose={handleCloseDenimModal}
                        slug={denimSlugNameArray}
                        messageID="text.sizeChart.denim"
                        brandName={productBrandName}
                      />
                    )}

                    {isRiseModalOpen && (
                      <SizeChartModal
                        isOpen={isRiseModalOpen}
                        onClose={handleCloseRiseModal}
                        slug={riseSlugNameArray}
                        messageID="text.sizeChart.rise.table"
                        brandName={productBrandName}
                      />
                    )}
                  </Box>

                  <SkeletonText isLoaded={isLoaded}>
                    {main}

                    <Button
                      size="lg"
                      variant={'ghost'}
                      width={'full'}
                      onClick={handleViewFullDetails}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                        '&:focus': {
                          backgroundColor: 'transparent',
                        },
                        '&:active': {
                          backgroundColor: 'transparent',
                        },
                      }}
                    >
                      <Box textStyle={'ui-button-200'}>
                        {intl.formatMessage({
                          id: 'action.viewFullDetails',
                        })}
                      </Box>
                    </Button>
                    {(isInStock || isLowStock || isBackordered || isDropShipProduct) && areAllAttributesSelected && (
                      <Box mb={2}>
                        <Text
                          textStyle="heading-desktop-75"
                          tabIndex={0}
                          aria-label={formatMessage({ id: 'product.availability.text' })}
                        >
                          {formatMessage({ id: 'product.availability.text' })}
                        </Text>
                      </Box>
                    )}
                    <Box mb={2}>
                      <StockMessage
                        isInStock={isInStock}
                        isLowStock={isLowStock}
                        isBackordered={isBackordered}
                        availabilityDate={availabilityDate}
                        firstAvailableChildProduct={firstAvailableChildProduct}
                        isDonationPdp={isDonationPdp}
                        isGiftCardPdp={isGiftCardPdp}
                        areAllAttributesSelected={areAllAttributesSelected}
                      />
                    </Box>
                    {isDropShipProduct && <DropShipProductMessage />}
                    {isShowPromoExclusion && (
                      <Box
                        marginTop={3}
                        role="textbox"
                        aria-label="Promotion Exclusion Information"
                        tabIndex={0}
                        textStyle={'body-75'}
                        color={'text'}
                        dangerouslySetInnerHTML={{ __html: promoExclusionMessage }}
                      />
                    )}
                  </SkeletonText>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Box>

      <Scene7ViewerScript
        isOpen={isOpen}
        onClose={onClose}
        productVideos={productVideos}
        currentImages={currentImages}
        currentImageIndex={currentImageIndex}
      />
    </>
  )
}

const ImageSection = ({ productId, images, videos, onClick, updateCurrentImageIndex }) => {
  const imagesAndVideos = [...images, ...videos]

  return (
    <Container
      position="relative"
      display="inline-block"
      maxW="397px"
      sx={{
        '.chakra-image': {
          pointerEvents: 'none',
          cursor: 'default',
        },
        '.chakra-aspect-ratio': {
          cursor: 'default',
        },
      }}
    >
      <Gallery
        key={productId}
        aspectRatio={1}
        rootProps={{
          overflow: 'hidden',
          width: '100%',
          borderRadius: 'base',
        }}
        imagesAndVideos={imagesAndVideos}
        onClick={(index) => {}}
        notifyCurrentSlideIndex={updateCurrentImageIndex}
      />
    </Container>
  )
}

const extractVideosFromParentProduct = (product: Product) => {
  const videoPattern = /_vid\d+$/ // Videos will end with _vid1, _vid2, etc.
  const videos: ImagesAndVideos[] = []

  if (product?.variants?.[0]?.images) {
    // Product videos are only stored in the Master variant
    product.variants[0].images.forEach((image) => {
      if (videoPattern.test(image)) {
        videos.push({ src: image, type: 'video' })
      }
    })
  }

  return videos
}

export default QuickViewProductDetailsLayout
